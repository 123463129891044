/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto&display=swap');

.cdm-preload {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	width: 0;
	z-index: -1000;
	width: 100%;
	height: 100%;
	font-weight: 200;

	& .text {
		// font-family: 'Montserrat', sans-serif !important;
	}
}

h1,
h2,
h3,
h4,
h5 {
	// font-family: 'Montserrat', sans-serif !important;
}

.color-picker {
	border: none !important;
	border-radius: 4px;

	& .saturation-lightness {
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}

	& .selected-color {
		border: none !important;
	}

	& .arrow {
		right: -20px !important;
	}
}